import { CourseChapterType } from '@/types/Course';

import { PublicationStatus } from './PublicationStatus';

export type KeysDeep = (string | Record<string, KeysDeep>)[];
export type SelectListItem = {
  value: string;
  label: string;
};
export type ChapterListItem = {
  value: string;
  label: string;
  type: CourseChapterType;
  status?: PublicationStatus;
};
export enum Languages {
  de = 'de',
  en = 'en',
}
export type Language = `${Languages}`;
export enum ChapterAccessList {
  free = 'free',
  login = 'login',
  membership = 'membership',
}
export type ChapterAccess = `${ChapterAccessList}`;
export enum ChapterAccessLoggedInList {
  login = 'login',
  membership = 'membership',
}
export type ChapterAccessLoggedIn = `${ChapterAccessLoggedInList}`;
