import {
  ChapterAccessList,
  ChapterAccessLoggedInList,
  ContentTypes,
  CourseThemeList,
  CourseTypes,
  ExerciseRepetitions,
  ExerciseSelectQuestionsByList,
  ExerciseShowResults,
  Languages,
  PublicationStatuses,
  QuestionAnswerReferenceTypes,
  QuestionEffects,
  QuestionTypes,
} from '@/types';

export const sidebarWidth = 240;

export const searchMinLength = 3;

export const defaultSearch = {
  limit: 25,
  loading: false,
  order: 'title',
  page: 0,
  term: '',
  termFields: ['title'],
  total: 0,
};

export const bookingSessionCookieName = 'booking_session';

export const accessOptions = Object.values(ChapterAccessList).map((option) => ({
  id: option,
  label: option,
}));

export const accessLoggedInOptions = Object.values(ChapterAccessLoggedInList).map((option) => ({
  id: option,
  label: option,
}));

export const statusOptions = Object.values(PublicationStatuses).map((option) => ({
  id: option,
  label: option,
}));

export const languageOptions = Object.values(Languages).map((option) => ({
  id: option,
  label: option,
}));

export const exerciseSelectQuestionsByOptions = Object.values(ExerciseSelectQuestionsByList).map((option) => ({
  id: option,
  label: option,
}));

export const exerciseRepetitionOptions = Object.values(ExerciseRepetitions).map((option) => ({
  id: option,
  label: option,
}));

export const exerciseShowResultOptions = Object.values(ExerciseShowResults).map((option) => ({
  id: option,
  label: option,
}));

export const questionTypeOptions = Object.values(QuestionTypes).map((option) => ({
  id: option,
  label: option,
}));

export const questionEffectOptions = Object.values(QuestionEffects).map((option) => ({
  id: option,
  label: option,
}));

export const questionAnswerReferenceTypeOptions = Object.values(QuestionAnswerReferenceTypes).map((option) => ({
  id: option,
  label: option,
}));

export const contentTypeOptions = Object.values(ContentTypes).map((option) => ({
  id: option,
  label: option,
}));

export const courseTypeOptions = Object.values(CourseTypes).map((option) => ({
  id: option,
  label: option,
}));

export const courseThemeOptions = Object.values(CourseThemeList).map((option) => ({
  id: option,
  label: option,
}));
